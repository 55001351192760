import { HostType } from 'configs/theme'
import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import Container from './Container'
import Heading from './Heading'

const contentByHost: Partial<
  Record<
    HostType,
    {
      tagline: string
      description: string
      features: { tagline: string; description: string; image?: string }[]
    }
  >
> = {
  sellmonitor: {
    tagline: 'Умные инструменты помогут продавать больше',
    description: '',
    features: [
      {
        tagline: 'Личный кабинет',
        description:
          'Покажем подробный анализ вашего бизнеса со всеми метриками и графиками, чтобы вы могли принимать решения на основании чётких данных',
        image: '/next/img/landing/business_small.png',
      },
      {
        tagline: 'Расчёт поставок',
        description:
          'Вычислим, сколько и на какие склады нужно поставить товаров на основе истории продаж и географии заказов',
      },
      {
        tagline: 'SEO-оптимизация',
        description:
          'Умный алгоритм подберёт ключевые слова, которых не хватает вашему товару, на основе анализа конкурентов',
      },
      {
        tagline: 'Генерация описания товаров',
        description:
          'Сгенерируем описания для ваших товаров с помощью искусственного интеллекта и добавим в них нужные ключевые слова',
        image: '/next/img/landing/description_generation.png',
      },
      {
        tagline: 'Управление рекламой',
        description:
          'Поможем сэкономить на рекламе с помощью автоматического управления ставками и выбора оптимальных часов для продвижения на основе статистики',
        image: '/next/img/landing/bidder.png',
      },
      {
        tagline: 'Автоответы на отзывы',
        description:
          'Поддерживайте репутацию бренда с помощью автоматического ответа на отзывы: настройте шаблоны ответов, и мы ответим на положительные отзывы за вас',
      },
      {
        tagline: 'Бронирование слотов поставок',
        description: 'Автоматически забронируем слоты поставок на WB с нужным коэффициентом',
      },
      {
        tagline: 'Управление ценами',
        description:
          'Управляем ценой товаров на основании остатков, расписания и цен конкурентов; поможем сохранить цену, если включится акция',
        image: '/next/img/landing/repricer.png',
      },
    ],
  },
  uzum: {
    tagline: 'Aqlli vositalar ko‘proq sotishga yordam beradi',
    description: '',
    features: [
      {
        tagline: 'Shaxsiy kabinet',
        description:
          'Biznesingizning batafsil tahlilini barcha metrikalar va grafiklar bilan ko‘rsatamiz, shunda siz aniq ma’lumotlarga asoslangan qarorlar qabul qilishingiz mumkin',
        image: '/next/img/landing/business_small.png',
      },
      {
        tagline: 'Yetkazib berish hisob-kitobi',
        description:
          'Sotuvlar tarixi va buyurtmalar geografiyasiga asoslanib, qaysi omborlarga qancha mahsulot yetkazib berish kerakligini hisoblaymiz',
      },
      {
        tagline: 'SEO-optimallashtirish',
        description:
          'Aqlli algoritm raqobatchilar tahliliga asoslanib, mahsulotingizga yetishmayotgan kalit so‘zlarni tanlaydi',
      },
      {
        tagline: 'Mahsulot tavsiflarini yaratish',
        description:
          'Sun’iy intellekt yordamida mahsulotlaringiz uchun tavsiflar yaratamiz va ularga kerakli kalit so‘zlarni qo‘shamiz',
        image: '/next/img/landing/description_generation.png',
      },
      {
        tagline: 'Reklamani boshqarish',
        description:
          'Avtomatik stavkalarni boshqarish va reklama eng yaxshi soatlarda ishga tushirish orqali reklama xarajatlarini tejashga yordam beramiz',
        image: '/next/img/landing/bidder.png',
      },
      {
        tagline: 'Sharhlarga avtomatik javoblar',
        description:
          'Sharhlarga avtomatik javob berish orqali brend obro‘sini saqlang: javob shablonlarini sozlang va biz ijobiy sharhlarga siz uchun javob beramiz',
      },
    ],
  },
  sellscreen: {
    tagline: 'How to increase sales and lower costs?',
    description: 'We provide all the required tools so you can focus on growing your business',
    features: [
      {
        tagline: 'Niche analysis',
        description:
          'Discover potential best-selling products in untapped niches with less competition',
        image: '/next/img/landing/sellscreen/categories_small.png',
      },
      {
        tagline: 'Review tracking',
        description:
          "Manage you store's reputation by monitoring reviews and avoiding unanswered complaints",
      },
      {
        tagline: 'SEO-optimization',
        description:
          "Pick popular keywords for your products' description and track their position in the search results",
      },
      {
        tagline: 'Seasonal demand analysis',
        description: 'Easily validate your product’s success with at-a-glance analytics',
        image: '/next/img/landing/sellscreen/charts.png',
      },
      {
        tagline: 'Competitor analysis',
        description:
          "Track sales data by store, brand and product; copy your competitors' successful strategies and avoid their mistakes",
        image: '/next/img/landing/sellscreen/products_small.png',
      },
      {
        tagline: 'Excel exports',
        description: 'Download in-depth analytics data for use in third-party systems',
      },
    ],
  },
}

const Features: FC = () => {
  const ctx = useUserContext()
  const content = contentByHost[ctx.theme.hostType]!

  return (
    <Container className="bg-gray-900">
      <Heading dark tagline={content.tagline} description={content.description} />
      <ul
        role="list"
        className="grid grid-cols-1 grid-rows-6 gap-3 mx-auto mt-12 max-w-2xl text-sm sm:mt-16 sm:grid-cols-2 sm:grid-rows-3 lg:max-w-none lg:grid-cols-3 lg:grid-rows-2"
      >
        <li className="overflow-hidden relative p-8 bg-gradient-to-br from-white to-blue-100 rounded-2xl border border-gray-800 lg:col-span-2">
          <h3 className="text-base font-medium lg:w-1/2">{content.features[0].tagline}</h3>
          <p className="mt-2 lg:w-1/2">{content.features[0].description}</p>
          <img
            src={content.features[0].image}
            alt=""
            className="hidden absolute top-0 bottom-0 -right-24 lg:block xl:-right-1"
          />
        </li>
        <li className="p-8 bg-gradient-to-br from-blue-200 to-cyan-400 rounded-2xl border border-gray-800">
          <h3 className="text-base font-medium">{content.features[1].tagline}</h3>
          <p className="mt-2">{content.features[1].description}</p>
        </li>
        <li className="p-8 bg-gradient-to-tr from-lime-300 to-yellow-300 rounded-2xl border border-gray-800">
          <h3 className="text-base font-medium">{content.features[2].tagline}</h3>
          <p className="mt-2">{content.features[2].description}</p>
        </li>
        <li className="overflow-hidden relative p-8 bg-gradient-to-br from-violet-300 to-purple-400 rounded-2xl border border-gray-800 lg:row-span-2">
          <h3 className="text-base font-medium">{content.features[3].tagline}</h3>
          <p className="mt-2">{content.features[3].description}</p>
          <img
            src={content.features[3].image}
            alt=""
            className="hidden absolute bottom-0 left-0 w-full rounded-t-md lg:block"
          />
        </li>
        <li className="overflow-hidden relative flex flex-col justify-end p-8 bg-gradient-to-br from-gray-500 to-gray-100 rounded-2xl border border-gray-800 lg:row-span-2">
          <h3 className="text-base font-medium">{content.features[4].tagline}</h3>
          <p className="mt-2">{content.features[4].description}</p>
          <img
            src={content.features[4].image}
            alt=""
            className="hidden absolute top-0 left-0 w-full rounded-t-md lg:block"
          />
        </li>
        <li className="p-8 text-white bg-gradient-to-br from-gray-800 to-gray-600 rounded-2xl border border-gray-800">
          <h3 className="text-base font-medium">{content.features[5].tagline}</h3>
          <p className="mt-2">{content.features[5].description}</p>
        </li>
        {content.features[6] && (
          <li className="p-8 text-black bg-gradient-to-tl from-cyan-400 to-violet-300 rounded-2xl border border-gray-800">
            <h3 className="text-base font-medium">{content.features[6].tagline}</h3>
            <p className="mt-2">{content.features[6].description}</p>
          </li>
        )}
        {content.features[7] && (
          <li className="overflow-hidden relative p-8 bg-gradient-to-br from-lime-300 to-blue-100 rounded-2xl border border-gray-800 lg:col-span-2">
            <h3 className="text-base font-medium lg:w-1/2">{content.features[7].tagline}</h3>
            <p className="mt-2 lg:w-1/2">{content.features[7].description}</p>
            <img
              src={content.features[7].image}
              alt=""
              className="hidden absolute top-0 bottom-0 -right-64 lg:block xl:-right-27"
            />
          </li>
        )}
      </ul>
    </Container>
  )
}

export default Features
